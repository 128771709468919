import PrimaryButton from '../Buttons/PrimaryButton'
import Tooltip from '../Tooltip/Tooltip'
import { Link } from 'react-router-dom'
import ButtonSpinner from '../ButtonSpinner/ButtonSpinner'
import InfoMessage from '../InfoMessage/InfoMessage'
import { addRP } from '../../utils/utils'
import { useState } from 'react'
export default function AddRP({ characters = [], fetchRPs, closeModal }) {
    const [postIsLoading, setPostIsLoading] = useState(false)
    const [newLastDate, setNewLastDate] = useState('')
    const [newRPLink, setNewRPLink] = useState('')
    const [newRPName, setNewRPName] = useState('')
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [hasError, setHasError] = useState(false)

    const triggerError = () => {
        setHasError(true)
    }

    const handleCharacterChoice = (e) => {
        setSelectedCharacter(e.target.value)
    }

    const postRP = async () => {
        setPostIsLoading(true)
        try {
            await addRP(newRPLink, newRPName, selectedCharacter, newLastDate)
            setNewRPLink('')
            fetchRPs()
            closeModal()
        } catch (_) {
            triggerError()
        } finally {
            setPostIsLoading(false)
        }
    }
    return (
        <div className="text-center m-auto p-4">
            <div className="flex justify-center m-auto mt-4">
                <h3>Ajouter un RP à suivre</h3>
                <Tooltip tooltipStyle="sm:mt-[3px] mt-0" position={null}>
                    <p>
                        Pour que vos RPs soient trackés correctement, il faut
                        que :
                    </p>
                    <ul className="list-disc ml-8 my-4">
                        <li>
                            vos personnages sur Airpéger aient exactement le
                            même nom que sur vos forums
                        </li>
                        <li>
                            vous ajoutiez le lien de la 1ère page de votre RP
                        </li>
                    </ul>
                    <p>
                        Si vous avez une question ou un problème, n'hésitez pas
                        à me contacter pour qu'on regarde.
                    </p>
                </Tooltip>
            </div>
            {hasError && (
                <InfoMessage type="warning">
                    <p>
                        Une erreur est survenue au moment de suivre votre RP.
                        Veuillez ré-essayer.
                    </p>
                </InfoMessage>
            )}
            <div className="my-8">
                <input
                    type="text"
                    name="Lien du RP"
                    placeholder="Lien de mon RP"
                    className="px-8 py-4 mb-4 sm:w-9/12 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    onChange={(e) => setNewRPLink(e.target.value)}
                    value={newRPLink}
                />
                <input
                    type="text"
                    name="Nom de mon RP"
                    placeholder="Nom de mon RP"
                    className="px-8 py-4 mb-4 sm:w-9/12 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary"
                    onChange={(e) => setNewRPName(e.target.value)}
                    value={newRPName}
                />
                <select
                    className="px-8 py-4 mb-4 sm:w-9/12 bg-background w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary appearance-none"
                    onChange={handleCharacterChoice}
                    data-testid="character-select">
                    <option value="">Nom du personnage lié au RP</option>
                    {characters.map((item, index) => (
                        <option key={`${item}-${index}`} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                <br />
                <label htmlFor="rpdate" className="text-sm">
                    Date de dernière réponse au RP
                </label>
                <br />
                <input
                    id="rpdate"
                    type="date"
                    name="Date de dernière réponse"
                    placeholder="Date de dernière réponse"
                    className="px-8 py-4 mb-4 sm:w-9/12 w-full rounded-md border border-solid border-border-color focus:outline focus:outline-secondary mt-2"
                    onChange={(e) => setNewLastDate(e.target.value)}
                    value={newLastDate}
                />
                <br />
                <Link
                    to="/personnages"
                    onClick={closeModal}
                    className="text-secondary">
                    Gérer mes personnages
                </Link>
            </div>
            <div className="flex justify-center">
                <PrimaryButton clickFunc={postRP}>
                    {postIsLoading ? <ButtonSpinner /> : 'Ajouter le RP'}
                </PrimaryButton>
            </div>
        </div>
    )
}
