export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max)
}

export const removeSpaces = (text) => {
    return text.replaceAll('<p><br></p>', '')
}

export const makeCall = async (url, options, auth) => {
    const res = await fetch(url, options)
    if (!res.ok) {
        if (res.status === 401) {
            if (auth) {
                throw new Error('Unauthorized')
            } else {
                window.location.href = '/'
                return
            }
        } else {
            throw new Error('Error', res.status)
        }
    }
    const responseBody = await res.json()
    return { data: responseBody, headers: res.headers }
}

// TRACKER

export const getRPs = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getrps', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const checkRPs = async (rpLink, characters) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/checkrp', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ url: rpLink, characters: characters }),
    })
}

export const addRP = async (
    newRPLink,
    newRPName,
    newRPCharacter,
    newLastDate
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/trackrp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            rpLink: newRPLink,
            rpName: newRPName,
            rpCharacter: newRPCharacter,
            lastDate: newLastDate,
        }),
    })
}

export const updateRP = async (
    rpLink,
    newRPName,
    newRPCharacter,
    newLastDate
) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/updaterp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            rpLink: rpLink,
            rpName: newRPName,
            rpCharacter: newRPCharacter,
            lastDate: newLastDate,
        }),
    })
}

export const deleteRP = async (RPToDelete) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/deleterp', {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({ rpLink: RPToDelete }),
    })
}

// CHARACTERS

export const getCharacters = async () => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/getcharacters',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
        }
    )
}

export const addCharacter = async (newCharacterName) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/addcharacter',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ characterName: newCharacterName }),
        }
    )
}

export const deleteCharacter = async (characterName) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/deletecharacter',
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ characterName: characterName }),
        }
    )
}

// AUTH

export const login = async (pseudo, password) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/login',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ pseudo: pseudo, password: password }),
        },
        true
    )
}

export const signup = async (pseudo, password, email) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/signup',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({
                pseudo: pseudo,
                password: password,
                email: email,
            }),
        },
        true
    )
}

export const requestPassword = async (email) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/auth/requestpassword',
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ email: email }),
        }
    )
}

export const editPassword = async (password, token) => {
    return await makeCall('https://airpeger-api.vercel.app/auth/editpassword', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: password, token: token }),
    })
}

export const getAuthToken = () =>
    `Bearer ${localStorage.getItem('airpeger-token')}`

export const getUser = async () => {
    if (localStorage.getItem('airpeger-token')) {
        function getPayload(jwt) {
            return JSON.parse(atob(jwt.split('.')[1]))
        }
        const payload = getPayload(localStorage.getItem('airpeger-token'))
        const expiration = new Date(payload.exp * 1000)
        const now = new Date()

        if (expiration < now) {
            return false
        } else {
            return await makeCall(
                'https://airpeger-api.vercel.app/account/getuserinfo',
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: getAuthToken(),
                    },
                }
            )
        }
    } else {
        return false
    }
}

// STATS

export const getStats = async () => {
    return await makeCall('https://airpeger-api.vercel.app/rp/getstats', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const getGlobalStats = async () => {
    return await makeCall(
        'https://airpeger-api.vercel.app/stats/getuserstats',
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
    )
}

// ACCOUNT

export const updateUser = async (password, picture) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updateuserinfo',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ password: password, picture: picture }),
        }
    )
}

// DRAFTS

export const getDraft = async () => {
    return await makeCall('https://airpeger-api.vercel.app/account/getdraft', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
    })
}

export const updateDraft = async (draft) => {
    return await makeCall(
        'https://airpeger-api.vercel.app/account/updatedraft',
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: getAuthToken(),
            },
            body: JSON.stringify({ draft: draft }),
        }
    )
}

// RP

export const postRP = async (characterName, wordCount, characterCount) => {
    return await makeCall('https://airpeger-api.vercel.app/rp/publishrp', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
        },
        body: JSON.stringify({
            characterName: characterName,
            wordCount: wordCount,
            characterCount: characterCount,
        }),
    })
}
